import { useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { usePostHog } from 'posthog-js/react';
import {
  PickerFileMetadata,
  PickerOptions,
  PickerResponse,
} from 'filestack-js';

import {
  declineBid,
  getAdditionalInfo,
  getAttributes,
  getAuctionMetrics,
  getDealerContacts,
  saveChanges,
} from 'api/api';
import { DeclineReason } from 'types/enum';
import { uploadFile } from 'services/filestack';
import { useCmsData } from 'contexts/CmsDataProvider';
import { AdditionalInfo } from 'types/types';

const RQ_ADDITIONAL_INFO = 'additionalInfo';

export const useAdditionalInfo = () => {
  const submissionId = window.location.pathname
    ?.split('/u/')
    ?.pop()
    ?.split('/')[0];

  return {
    ...useQuery(RQ_ADDITIONAL_INFO, () => getAdditionalInfo(submissionId!), {
      enabled: !!submissionId,
    }),
    submissionId,
  };
};

export const useAttributes = () => useQuery('attributes', getAttributes);

export const useDealerContacts = (
  submissionId: string,
  { enabled = false }: { enabled: boolean }
) =>
  useQuery(
    ['dealerContacts', submissionId],
    () => getDealerContacts(submissionId),
    {
      enabled,
      retry: false,
    }
  );

export const useAuctionMetrics = (
  submissionId: string,
  { enabled = false }: { enabled: boolean }
) =>
  useQuery(
    ['auctionMetrics', submissionId],
    () => getAuctionMetrics(submissionId || ''),
    {
      enabled,
    }
  );

type FileType = 'regDoc' | 'photo' | 'additionalPhoto';

export const useUploadFile = () => {
  const ph = usePostHog();

  const { locale } = useCmsData();
  const { submissionId, refetch } = useAdditionalInfo();
  const queryClient = useQueryClient();

  const mutation = useMutation('uploadFile', (data: any) => saveChanges(data), {
    onSuccess: () => refetch(),
    onError: () => alert("Couldn't upload the image"),
  });

  const { mutate, mutateAsync } = mutation;

  const mutateCache = (handler: (updData: AdditionalInfo) => any) => {
    queryClient.setQueryData(RQ_ADDITIONAL_INFO, (prevData: unknown) => {
      if (prevData) {
        const updData = { ...prevData } as AdditionalInfo;

        handler(updData);

        return updData;
      }

      return prevData;
    });
  };

  const onUploadDone = async (
    res: PickerResponse,
    { type, fileIndex }: { type: FileType; fileIndex?: number }
  ) => {
    if (type === 'regDoc') {
      mutate({
        registration_documents: [{ url: res.filesUploaded[0].url }],
        submission_id: submissionId,
      });
    } else if (type === 'photo') {
      mutate({
        photo: { url: res.filesUploaded[0].url, index: fileIndex },
        submission_id: submissionId,
      });
    } else if (type === 'additionalPhoto') {
      for (let i = 0; i < res.filesUploaded.length; i++) {
        const url = res.filesUploaded[i].url;

        await mutateAsync({
          additional_photo: { url },
          submission_id: submissionId,
        });
      }
    }
  };

  const handleUploadFile = ({
    options,
    type,
    fileIndex,
    recipe,
  }: {
    options: PickerOptions;
    type: FileType;
    fileIndex?: number;
    recipe?: object[];
  }) => {
    const session_id = uuid();
    const submission_id = submissionId;

    return uploadFile({
      lang: locale,
      onOpen: () => {
        ph.capture('upload_open', {
          session_id,
          submission_id,
          timestamp: new Date().toISOString(),
        });
      },
      onClose: () => {
        ph.capture('upload_close', {
          session_id,
          submission_id,
          timestamp: new Date().toISOString(),
        });
      },
      onFileUploadStarted(file: PickerFileMetadata) {
        ph.capture('upload_start', {
          session_id,
          submission_id,
          file_size: file.size,
          file_type: file.mimetype,
          timestamp: new Date().toISOString(),
        });
      },
      onFileUploadFailed(file: PickerFileMetadata, error: Error) {
        ph.capture('upload_fail', {
          error,
          session_id,
          submission_id,
          file_size: file.size,
          file_type: file.mimetype,
          timestamp: new Date().toISOString(),
        });
      },
      onFileUploadFinished(file: PickerFileMetadata) {
        ph.capture('upload_finish', {
          session_id,
          submission_id,
          file_size: file.size,
          file_type: file.mimetype,
          timestamp: new Date().toISOString(),
        });
      },
      onUploadDone: (res) => onUploadDone(res, { type, fileIndex }),
      onFileSelected: async (file) => ({
        ...file,
        filename: recipe
          ? btoa(JSON.stringify(recipe)).replace(/=/g, '_')
          : file.filename,
      }),
      ...options,
    });
  };

  const removeFile = ({
    type,
    fileIndex,
  }: {
    type: FileType;
    fileIndex?: number;
  }) => {
    if (type === 'regDoc') {
      mutateCache((updData) => {
        updData.registrationDocuments = [];
      });

      mutate({
        registration_documents: ['-'],
        submission_id: submissionId,
      });
    } else if (type === 'photo' && fileIndex) {
      mutateCache((updData) => {
        if (updData.photos[fileIndex]) {
          updData.photos[fileIndex].url = '';
        }
      });

      mutate({
        photo: { url: '-', index: fileIndex },
        submission_id: submissionId,
      });
    } else if (type === 'additionalPhoto' && fileIndex) {
      mutateCache((updData) => {
        if (updData.additionalPhotos[fileIndex]) {
          updData.additionalPhotos.splice(fileIndex, 1);
        }
      });

      mutate({
        additional_photo: { url: '-', index: fileIndex },
        submission_id: submissionId,
      });
    }
  };

  return { ...mutation, uploadFile: handleUploadFile, removeFile };
};

export const useDeclineBid = (
  submissionId: string,
  refetch: () => void,
  onDismiss: () => void
) => {
  const [counterOffer, setCounterOffer] = useState<number | undefined>(
    undefined
  );
  const [otherReason, setOtherReason] = useState<string>('');
  const [selectedReasons, setSelectedReasons] = useState<{
    [key in DeclineReason]?: boolean;
  }>({});

  const { mutate: onDeclineBid, isLoading: isDeclineBidLoading } = useMutation(
    'declineBid',
    () =>
      declineBid({
        submissionId: submissionId!,
        declineReasons: {
          ...selectedReasons,
          ...(selectedReasons[DeclineReason.Other] && {
            [DeclineReason.Other]:
              otherReason.length > 0 ? otherReason : selectedReasons.other,
          }),
        },
        counterOffer: counterOffer,
      }),
    {
      onSuccess: async () => {
        await refetch();
        onDismiss();
      },
      onError: () =>
        alert(
          'Unfortunately something went wrong! Please try again later or contact us.'
        ),
    }
  );

  return {
    counterOffer,
    setCounterOffer,
    otherReason,
    setOtherReason,
    selectedReasons,
    setSelectedReasons,
    onDeclineBid,
    isDeclineBidLoading,
  };
};
