import parse from 'html-react-parser';

import Modal from 'components/ui/Modal';
import Input from 'components/ui/Input';
import Button from 'components/ui/Button';

import { useCmsData } from 'contexts/CmsDataProvider';
import { useAdditionalInfo, useDeclineBid } from 'hooks/queries';

type Props = {
  isOpen: boolean;
  onDismiss: () => any;
  openDecline: () => any;
};

const CounterOfferModal: React.FC<Props> = ({
  isOpen,
  onDismiss,
  openDecline,
}) => {
  const { t } = useCmsData();
  const {
    data: additionalInfo,
    submissionId,
    refetch,
    isFetching,
  } = useAdditionalInfo();
  const {
    auction: { highestBid },
    desiredPrice,
  } = additionalInfo!;
  const { counterOffer, setCounterOffer, onDeclineBid, isDeclineBidLoading } =
    useDeclineBid(submissionId || '', refetch, onDismiss);

  const counterOfferLowerThanHighestBid = !!(
    counterOffer && counterOffer <= highestBid
  );
  const counterOfferHigherThanDesiredPrice = !!(
    counterOffer &&
    null !== desiredPrice &&
    counterOffer > desiredPrice
  );

  return (
    <Modal
      isOpen={isOpen}
      title={t('dashboard_counter_offer_modal_title')}
      onDismiss={() => {
        openDecline();
        onDismiss();
      }}
      content={
        <>
          <p className="html-md">
            {parse(t('dashboard_counter_offer_modal_desc1'))}
          </p>
          <Input
            className="mt-4 sm:w-1/2"
            label={t('dashboard_decline_modal_counter_offer_input_label')}
            placeholder={t(
              'dashboard_decline_modal_counter_offer_input_placeholder'
            )}
            value={counterOffer || ''}
            maxLength={9}
            type="number"
            onChange={(counterOffer) => setCounterOffer(counterOffer as number)}
            autoComplete="off"
            invalid={
              counterOfferLowerThanHighestBid ||
              counterOfferHigherThanDesiredPrice
            }
            invalidMsg={t(
              counterOfferLowerThanHighestBid
                ? 'dashboard_decline_modal_counter_offer_input_invalid_msg'
                : 'dashboard_decline_modal_counter_offer_input_invalid_desired_price_msg'
            )}
          />
          <p className="html-md mt-4">
            {parse(t('dashboard_counter_offer_modal_desc2'))}
          </p>
        </>
      }
      footer={
        <div className="flex items-center gap-x-3">
          <Button
            className="w-1/2"
            label={t('dashboard_counter_offer_modal_confirm_btn')}
            icon={null}
            onClick={onDeclineBid}
            isLoading={isDeclineBidLoading || isFetching}
            isDisabled={
              !counterOffer ||
              counterOfferLowerThanHighestBid ||
              counterOfferHigherThanDesiredPrice
            }
          />
          <Button
            className="w-1/2"
            label={t('dashboard_counter_offer_modal_cancel_btn')}
            icon={null}
            onClick={() => {
              setCounterOffer(() => {
                setTimeout(() => {
                  onDeclineBid();
                }, 0);
                return undefined;
              });
            }}
            outline
          />
        </div>
      }
    />
  );
};

export default CounterOfferModal;
