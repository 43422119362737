export enum AuctionStatus {
  Incomplete = 'as24_incomplete',
  InReview = 'as24_in_review',
  Scheduled = 'as24_scheduled',
  Active = 'as24_active',
  PendingDecision = 'as24_pending_decision',
  PendingCounterOffer = 'as24_pending_counter_offer',
  Accepted = 'as24_accepted',
  Declined = 'as24_declined',
}

export enum DeclineReason {
  LowBid = 'lowPrice',
  TradeInCanceled = 'tradeInCanceled',
  Other = 'other',
}

export enum VehicleLocation {
  ZH = 'ZH',
  BE = 'BE',
  LU = 'LU',
  UR = 'UR',
  SZ = 'SZ',
  OW = 'OW',
  NW = 'NW',
  GL = 'GL',
  ZG = 'ZG',
  FR = 'FR',
  SO = 'SO',
  BS = 'BS',
  BL = 'BL',
  SH = 'SH',
  AR = 'AR',
  AI = 'AI',
  SG = 'SG',
  GR = 'GR',
  AG = 'AG',
  TG = 'TG',
  TI = 'TI',
  VD = 'VD',
  VS = 'VS',
  NE = 'NE',
  GE = 'GE',
  JU = 'JU',
}

export enum VehicleMobility {
  Drivable = 'drivable',
  Rollable = 'rollable',
  Immovable = 'immovable',
}
