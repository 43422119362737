import { forwardRef } from 'react';
import {
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from '@headlessui/react';
import cx from 'classnames';

import Tooltip from 'components/ui/Tooltip';

import { ReactComponent as ChevronDownIcon } from 'assets/icons/chevron-down.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';

export type Option = {
  name: string;
  value: string | number | null;
};

type Props = {
  className?: string;
  options: Option[];
  placeholder?: string;
  label: string;
  tooltip?: string;
  hint?: string;
  value: string | number | null | undefined;
  onChange: (option: Props['value']) => any;
};

const Dropdown = forwardRef<HTMLDivElement, Props>(
  (
    {
      className,
      options,
      placeholder = '',
      label,
      tooltip,
      hint,
      value,
      onChange,
    },
    ref
  ) => {
    const selectedOption = options.find((el) => el.value === value) || {
      name: '',
      value: null,
    };

    return (
      <div ref={ref} className={className}>
        <Listbox
          className="flex flex-col justify-between h-full"
          as="div"
          value={selectedOption}
          onChange={(option) => {
            onChange(option?.value || null);
          }}
        >
          <Label className="flex items-center font-bold mb-0.5">
            {label}
            {tooltip && (
              <Tooltip title={tooltip} color="var(--color-background)">
                <InfoIcon className="inline ml-1 size-5 cursor-pointer" />
              </Tooltip>
            )}
          </Label>
          <div className="relative">
            <ListboxButton className="relative w-full border border-border cursor-pointer rounded bg-background px-3 py-[11px] pr-10 text-left text-dark data-[open]:ring-2 data-[open]:ring-tertiary-tint focus:outline-none focus:ring-2 focus:ring-tertiary-tint">
              <span
                className={cx(
                  'block truncate',
                  !selectedOption?.name && 'text-border'
                )}
              >
                {selectedOption?.name || placeholder}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronDownIcon className="size-6 text-dark" />
              </span>
            </ListboxButton>

            <ListboxOptions
              transition
              className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-background py-1 text-base shadow-lg border border-border-light focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
            >
              {options.map((option, i) => (
                <ListboxOption
                  key={i}
                  value={option}
                  className="group relative cursor-pointer select-none py-2 pl-3 pr-9 text-dark data-[focus]:bg-tertiary-light data-[focus]:text-dark data-[selected]:!bg-tertiary-tint"
                >
                  <span className="block font-normal group-data-[selected]:font-bold">
                    {option.name}
                  </span>

                  <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-primary group-data-[focus]:text-dark group-data-[selected]:text-dark [.group:not([data-selected])_&]:hidden">
                    <CheckIcon className="size-5" />
                  </span>
                </ListboxOption>
              ))}
            </ListboxOptions>

            {hint && <p className="mt-0.5 text-sm text-border">{hint}</p>}
          </div>
        </Listbox>
      </div>
    );
  }
);

export default Dropdown;
