import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import parse from 'html-react-parser';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import cx from 'classnames';

import PriceRange from 'components/PriceRange';
import Button from 'components/ui/Button';
import Input from 'components/ui/Input';
import Modal from 'components/ui/Modal';
import Spinner from 'components/ui/Spinner/Spinner';
import EditMileageModal from './EditMileageModal';

import { editMileage, saveChanges } from 'api/api';
import { useCmsData } from 'contexts/CmsDataProvider';
import { trackEvent } from 'services/plausible';
import { useAdditionalInfo } from 'hooks/queries';
import { numberWithSeparator } from 'utils/utils';

import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow-left.svg';

type Props = {
  className?: string;
  goBack: () => any;
  goNext: () => any;
};

const MinPrice: React.FC<Props> = ({ className, goBack, goNext }) => {
  const [minPrice, setMinPrice] = useState<number | null>(null);
  const [showEstimationPriceModal, setShowEstimationPriceModal] =
    useState(false);
  const [showEditMileageModal, setShowEditMileageModal] = useState(false);

  const { t } = useCmsData();
  const { data, submissionId = '', refetch } = useAdditionalInfo();

  useEffect(() => {
    setMinPrice(data?.desiredPrice || null);
  }, [data?.desiredPrice]);

  const { mutate: saveMinPrice, isLoading: isLoadingSaveMinPrice } =
    useMutation(
      'saveMinPrice',
      () =>
        saveChanges({
          submission_id: submissionId,
          desired_price: minPrice,
        }),
      {
        onSuccess: () => {
          trackEvent('as24-step6-selling-price');
          goNext();
        },
        onError: () =>
          alert(
            'Unfortunately something went wrong! Please try again later or contact us.'
          ),
      }
    );
  const { mutate: onEditMileage, isLoading: isLoadingEditMileage } =
    useMutation(
      'editMileage',
      (editedMileage: number) => editMileage({ submissionId, editedMileage }),
      {
        retry: 3,
        onSuccess: () => refetch(),
        onError: () =>
          alert(
            'Unfortunately something went wrong! Please try again later or contact us.'
          ),
      }
    );

  if (!data) {
    return <></>;
  }

  const {
    vehicleInfo: { make, model, exactModel, mileage, editedMileage },
    priceFrom: min,
    priceTo: max,
  } = data;

  return (
    <>
      <EditMileageModal
        isOpen={showEditMileageModal}
        onDismiss={() => setShowEditMileageModal(false)}
        onSubmit={(editedMileage) => {
          if (!isLoadingEditMileage) {
            onEditMileage(editedMileage);
          }
          setShowEditMileageModal(false);
        }}
        vehicleInfo={data.vehicleInfo}
        min={min}
        max={max}
      />
      <Modal
        isOpen={showEstimationPriceModal}
        title={t('estimation_price_desc_modal_title')}
        description={parse(t('estimation_price_desc_modal_description'))}
        btnText={t('estimation_price_desc_modal_button')}
        onDismiss={() => setShowEstimationPriceModal(false)}
        onConfirm={() => setShowEstimationPriceModal(false)}
      />

      <div className={className}>
        <p className="text-[24px] mb-[12px] font-bold">
          {t('min_price_no_estimation_title')}
        </p>
        <div className="mb-[16px]">
          <ReactMarkdown
            className="markdown"
            rehypePlugins={[rehypeRaw]}
            children={t('min_price_no_estimation_subtitle')}
          />
        </div>
        <div className="block border border-highlight-shade rounded overflow-hidden">
          <div className="flex flex-col md:flex-row p-8 pb-10 bg-highlight-tint">
            <Input
              className="md:w-[320px] lg:w-[424px]"
              label={t('min_price_input_label')}
              placeholder={t('min_price_input_placeholder')}
              value={minPrice || ''}
              maxLength={9}
              inputMode="numeric"
              type="number"
              onChange={(minPrice) => setMinPrice(minPrice as number)}
              autoComplete="off"
            />
            <div className="mt-5 md:ml-[70px]">
              <div>
                {make} {model} {exactModel}
              </div>
              <div className="flex items-center gap-x-2">
                <div>
                  {numberWithSeparator(editedMileage || mileage)} {t('km')}
                </div>
                <div
                  className={cx(
                    'cursor-pointer',
                    editedMileage ? 'underline' : 'text-sm text-tertiary'
                  )}
                  onClick={() => setShowEditMileageModal(true)}
                >
                  {t('min_price_edit_link')}
                </div>
              </div>
            </div>
          </div>
          {min && max && (
            <div className="flex flex-col md:flex-row p-8">
              {!isLoadingEditMileage ? (
                <PriceRange
                  className="shrink-0 md:w-[320px] lg:w-[424px]"
                  currentPrice={minPrice}
                  min={min}
                  max={max}
                />
              ) : (
                <div className="flex mb-8 md:mb-0 flex-col justify-center items-center gap-y-3 shrink-0 md:w-[320px] lg:w-[424px]">
                  <div className="font-bold">
                    {t('min_price_recalculating_text')}
                  </div>
                  <Spinner type="round" />
                </div>
              )}
              <div className="md:ml-[70px] md:max-w-[620px]">
                <p className="w-full">{parse(t('min_price_description'))}</p>
                <div
                  className="underline cursor-pointer"
                  onClick={() => setShowEstimationPriceModal(true)}
                >
                  {t('min_price_description_link')}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="mt-6 pb-20 flex flex-col gap-y-3 sm:flex-row justify-between">
          <Button
            className="!bg-background !border !border-dark"
            label={t('min_price_prev_btn')}
            iconSlot="start"
            icon={<ArrowLeftIcon className="mr-1 size-6" />}
            onClick={goBack}
          />
          <Button
            label={t('min_price_next_btn')}
            onClick={saveMinPrice}
            isLoading={isLoadingSaveMinPrice}
            isDisabled={!minPrice || isLoadingEditMileage}
          />
        </div>
      </div>
    </>
  );
};

export default MinPrice;
