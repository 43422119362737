import { useState, ChangeEvent, useEffect } from 'react';
import { useMutation } from 'react-query';
import { AnimatePresence, motion } from 'framer-motion';
import _ from 'lodash';
import Refiner from 'refiner-js';
import parse from 'html-react-parser';
import { Carousel } from 'antd';

import Button from 'components/ui/Button';
import Footer from 'components/Footer';
import Input from 'components/ui/Input';
import AnswerList from 'components/AnswerList';
import Dropdown from 'components/ui/Dropdown';
import Alert, { AlertType } from 'components/ui/Alert';
import ImageWithBlur from 'components/ui/ImageWithBlur';
import EditFirstRegistrationModal from './EditFirstRegistrationModal';
import FinalCheckModal from './FinalCheck';

import { useAdditionalInfo } from 'hooks/queries';
import { useCmsData } from 'contexts/CmsDataProvider';
import { editFirstRegistration, saveChanges } from 'api/api';
import { IS_PRODUCTION } from 'constants/constants';
import useLowDemand from 'hooks/useLowDemand';
import {
  formatNumber,
  getTabsStatus,
  numberWithSeparator,
  validateInput,
} from 'utils/utils';
import { trackEvent } from 'services/plausible';
import { VehicleLocation, VehicleMobility } from 'types/enum';

import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';
import { ReactComponent as RoadIcon } from 'assets/icons/road.svg';
// import { ReactComponent as EyeIcon } from 'assets/icons/eye.svg';
import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow-left.svg';
import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrow-right.svg';

type Props = {
  className?: string;
  goBack: () => any;
};

const INITIAL_FORM_DATA: {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  street: string;
  city: string;
  zip: string;
  mobility: VehicleMobility | null;
  location: VehicleLocation | null;
} = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  street: '',
  city: '',
  zip: '',
  mobility: null,
  location: null,
};

const locationOptions = _.orderBy(
  Object.keys(VehicleLocation).map((el) => ({
    name: el,
    value: el,
  })),
  ['name'],
  ['asc']
);

const mobilityOptions = (t: Function) => [
  {
    name: t(`vehicle_mobility_status_${VehicleMobility.Drivable}`),
    value: VehicleMobility.Drivable,
  },
  {
    name: t(`vehicle_mobility_status_${VehicleMobility.Rollable}`),
    value: VehicleMobility.Rollable,
  },
  {
    name: t(`vehicle_mobility_status_${VehicleMobility.Immovable}`),
    value: VehicleMobility.Immovable,
  },
];

const Offer: React.FC<Props> = ({ className, goBack }) => {
  const [formData, setFormData] = useState(INITIAL_FORM_DATA);
  const [errors, setErrors] = useState<{
    emailInvalid?: boolean;
    phoneInvalid?: boolean;
  }>({});
  const [showEditFirstRegistrationModal, setShowEditFirstRegistrationModal] =
    useState(false);
  const [showFinalCheckModal, setShowFinalCheckModal] = useState(false);
  const [showAditionalInfo, setShowAditionalInfo] = useState(false);

  const {
    data: additionalInfo,
    submissionId = '',
    refetch,
    isFetching,
  } = useAdditionalInfo();
  const { data: cmsData, t, locale } = useCmsData();
  const { isLowDemand } = useLowDemand();

  useEffect(() => {
    if (additionalInfo?.contact && additionalInfo.vehicleInfo) {
      const {
        contact: { firstName, lastName, email, phone },
        vehicleInfo: { location, mobility },
      } = additionalInfo;
      const street = additionalInfo.contact?.address?.street || '';
      const city = additionalInfo.contact?.address?.city || '';
      const zip = additionalInfo.contact?.address?.zip || '';

      setFormData({
        firstName,
        lastName,
        email,
        phone,
        street,
        city,
        zip,
        location,
        mobility,
      });
    } else {
      setFormData(INITIAL_FORM_DATA);
    }
  }, [additionalInfo]);

  const { mutate, isLoading } = useMutation(
    () =>
      saveChanges({
        submission_id: submissionId!,
        contact: {
          first_name: formData.firstName,
          last_name: formData.lastName,
          email: formData.email,
          phone: formData.phone,
          street: formData.street,
          city: formData.city,
          zip: formData.zip,
        },
        location: formData.location,
        mobility: formData.mobility,
      }),
    {
      onSuccess: () => {
        trackEvent('as24-step7-start-auction');
        refetch();

        if (IS_PRODUCTION) {
          Refiner('showForm', '959d89b0-4e6f-11ef-b001-4dfc636c2789');
        }
      },
      onError: () =>
        alert(
          'Unfortunately something went wrong! Please try again later or contact us.'
        ),
    }
  );

  const {
    mutate: onEditFirstRegistration,
    isLoading: isLoadingEditFirstRegistration,
  } = useMutation(
    'editFirstRegistration',
    (date: { month: number; year: number }) =>
      editFirstRegistration({
        submissionId,
        month: date.month,
        year: date.year,
      }),
    {
      retry: 3,
      onSuccess: () => refetch(),
      onError: () =>
        alert(
          'Unfortunately something went wrong! Please try again later or contact us.'
        ),
    }
  );

  const handleChange = (
    name: keyof typeof formData,
    value: string | number | null | ChangeEvent<HTMLInputElement>
  ) => {
    if (typeof value === 'string' && (name === 'email' || name === 'phone')) {
      const validationErrors = validateInput(name, value);
      setErrors((prevErrors) => ({
        ...prevErrors,
        ...validationErrors,
      }));
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const toggleShowAditionalInfo = () => setShowAditionalInfo((prev) => !prev);

  if (!additionalInfo) {
    return <></>;
  }
  const {
    make,
    model,
    exactModel,
    firstRegistrationMonth,
    firstRegistrationYear,
    mileage,
    editedMileage,
  } = additionalInfo.vehicleInfo;

  const { disabledRegDoc, disabledPhotos, disabledMinPrice, disabledOffer } =
    getTabsStatus(cmsData, additionalInfo);

  const isFormInvalid =
    disabledRegDoc ||
    disabledPhotos ||
    disabledMinPrice ||
    disabledOffer ||
    !formData.firstName ||
    !formData.lastName ||
    !formData.email ||
    !formData.phone ||
    !formData.street ||
    !formData.zip ||
    !formData.city ||
    !formData.location ||
    !formData.mobility ||
    errors.emailInvalid ||
    errors.phoneInvalid;

  return (
    <>
      <FinalCheckModal
        isOpen={showFinalCheckModal}
        onDismiss={() => setShowFinalCheckModal(false)}
        isLoading={isLoading || isFetching}
        onSubmit={() => {
          if (IS_PRODUCTION) {
            window.dataLayer.push({
              event: 'gtmEvent',
              eventCategory: 'auction_flow',
              eventAction: 'auction_start',
            });
          }
          mutate();
        }}
      />
      <EditFirstRegistrationModal
        isOpen={showEditFirstRegistrationModal}
        onDismiss={() => setShowEditFirstRegistrationModal(false)}
        onSubmit={(month, year) => {
          if (!isLoadingEditFirstRegistration) {
            onEditFirstRegistration({ month, year });
          }
          setShowEditFirstRegistrationModal(false);
        }}
        vehicleInfo={additionalInfo.vehicleInfo}
        month={firstRegistrationMonth}
        year={firstRegistrationYear}
      />
      <div className={className}>
        <div className="flex justify-between gap-10 flex-col lg:flex-row">
          <div className="max-w-[456px]">
            <Carousel
              className="w-full max-w-[456px]"
              arrows
              dotPosition="bottom"
              infinite
              prevArrow={<ArrowLeftIcon />}
              nextArrow={<ArrowRightIcon />}
            >
              {[
                ...additionalInfo!.photos,
                ...additionalInfo!.additionalPhotos,
              ].map((photo) => (
                <div key={photo.id}>
                  <ImageWithBlur
                    src={photo.url}
                    alt="car"
                    className="h-[250px] md:h-[300px]"
                  />
                </div>
              ))}
            </Carousel>
            <h3 className="my-3 font-bold">
              {make} {model} {exactModel}
            </h3>
            <div className="font-bold text-2xl leading-[30px]">
              {t('offer_min_price', {
                price: formatNumber(additionalInfo.desiredPrice || 0, {
                  price: true,
                  hiddenFloat: true,
                }),
              })}
            </div>
            <div className="mt-3 flex items-center gap-x-2">
              <div className="flex items-center gap-x-1">
                <CalendarIcon className="size-4" />
                {(firstRegistrationMonth + 1).toString().padStart(2, '0')}.
                {firstRegistrationYear}
              </div>
              <div
                className="cursor-pointer text-sm text-tertiary"
                onClick={() => setShowEditFirstRegistrationModal(true)}
              >
                {t('first_reg_date_edit_link')}
              </div>
            </div>
            <div className="mt-1 flex items-center gap-x-1">
              <RoadIcon className="size-4" />
              {numberWithSeparator(editedMileage || mileage)} {t('km')}
            </div>
            {/* TODO */}
            {/* <div className="mt-6 flex items-center text-tertiary gap-x-2.5 cursor-pointer">
              <EyeIcon className="size-6" />
              {t('offer_preview_link')}
            </div> */}
            <div
              className="mt-3 flex items-center text-tertiary gap-x-2.5 cursor-pointer"
              onClick={toggleShowAditionalInfo}
            >
              {!showAditionalInfo ? (
                <ArrowRightIcon className="size-6 rotate-90" />
              ) : (
                <ArrowRightIcon className="size-6 -rotate-90" />
              )}
              {!showAditionalInfo
                ? t('offer_show_more_info_link')
                : t('offer_hide_more_info_link')}
            </div>
            <AnimatePresence>
              {showAditionalInfo && (
                <motion.div
                  className="overflow-hidden"
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: 'auto' }}
                  exit={{ opacity: 0, height: 0 }}
                  transition={{ duration: 0.15 }}
                >
                  <AnswerList
                    answers={additionalInfo.answers}
                    additionalDescription={
                      additionalInfo.additionalDescription?.[locale]
                    }
                  />
                </motion.div>
              )}
            </AnimatePresence>
          </div>
          <div className="shrink-0 w-full lg:w-[610px]">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 items-stretch">
              <Input
                label={t('offer_form_first_name')}
                name="firstName"
                value={formData.firstName}
                type="text"
                onChange={(value) => handleChange('firstName', value)}
                autoComplete="off"
              />
              <Input
                label={t('offer_form_last_name')}
                name="lastName"
                value={formData.lastName}
                type="text"
                onChange={(value) => handleChange('lastName', value)}
                autoComplete="off"
              />
              <Input
                label={t('offer_form_email')}
                name="email"
                value={formData.email || ''}
                maxLength={50}
                type="email"
                invalid={errors.emailInvalid}
                invalidMsg={t('error_message_invalid_email')}
                onChange={(value) => handleChange('email', value)}
                autoComplete="off"
              />
              <Input
                label={t('offer_form_phone')}
                name="phone"
                value={formData.phone}
                type="text"
                inputMode="numeric"
                invalid={errors.phoneInvalid}
                invalidMsg={t('error_message_invalid_phone')}
                onChange={(value) => handleChange('phone', value)}
                autoComplete="off"
              />
              <Input
                className="md:col-span-2"
                label={t('offer_form_street')}
                name="street"
                value={formData.street}
                type="text"
                onChange={(value) => handleChange('street', value)}
                autoComplete="off"
              />
              <Input
                label={t('offer_form_zip')}
                name="zip"
                value={formData.zip || ''}
                maxLength={30}
                type="text"
                onChange={(value) => handleChange('zip', value)}
                autoComplete="off"
              />
              <Input
                label={t('offer_form_city')}
                name="offer_city"
                value={formData.city || ''}
                maxLength={30}
                type="text"
                onChange={(value) => handleChange('city', value)}
                autoComplete="off"
              />
              <Dropdown
                label={t('vehicle_be_picked_up_title')}
                options={locationOptions}
                value={formData.location}
                onChange={(value) =>
                  handleChange('location', value as VehicleLocation)
                }
                placeholder={t('vehicle_be_picked_up_placeholder')}
                hint={t('vehicle_be_picked_up_hint')}
                tooltip={t('vehicle_be_picked_up_tooltip')}
              />
              <Dropdown
                label={t('vehicle_mobility_status')}
                options={mobilityOptions(t)}
                value={formData.mobility}
                onChange={(value) =>
                  handleChange('mobility', value as VehicleMobility)
                }
                placeholder={t('vehicle_mobility_status_placeholder')}
              />
            </div>
            <div className="flex w-full mt-6">
              <ul className="list-disc px-4">
                <li className="ml-1 break-words overflow-wrap break-word">
                  {t('offer_form_bullet_1')}
                </li>
                <li className="ml-1 break-words overflow-wrap break-word">
                  {t('offer_form_bullet_2')}
                </li>
                <li className="ml-1 break-words overflow-wrap break-word">
                  {t('offer_form_bullet_3')}
                </li>
                <li className="ml-1 break-words overflow-wrap break-word">
                  {t('offer_form_bullet_4')}
                </li>
              </ul>
            </div>
            {isLowDemand && (
              <Alert
                className="mt-6"
                title={t('offer_low_demand_alert_title')}
                description={parse(t('offer_low_demand_alert_desc'))}
                type={AlertType.Default}
              />
            )}
          </div>
        </div>
        <div className="mt-12 pb-20 flex gap-y-3 flex-row justify-between">
          <Button
            className="!bg-background !border !border-dark"
            label={t('offer_prev_btn')}
            iconSlot="start"
            icon={<ArrowLeftIcon className="mr-1 size-6" />}
            onClick={goBack}
          />
          <Button
            className="lg:w-[610px]"
            label={t('offer_start_auction')}
            onClick={() => setShowFinalCheckModal(true)}
            isDisabled={isFormInvalid}
          />
        </div>
      </div>
      <Footer className="mt-0 md:mt-[246px]" />
    </>
  );
};

export default Offer;
