export const GET_CMS_DATA = '/getWidgetContent';
export const GET_ATTRIBUTES = '/getAttributes';
export const GET_ADDITIONAL_INFO = '/getAdditionalInfo';
export const SET_ADDITIONAL_DESCRIPTION = '/setAdditionalDescription';
export const SAVE_CHANGES = '/saveSubmissionChanges';
export const EDIT_MILEAGE = '/editMileage';
export const EDIT_FIRST_REGISTRATION = '/editFirstRegistration';
export const GET_DEALER_CONTACTS = '/getDealerContacts';
export const RESTART_AUCTION = '/restartAuction';

// CAD2
export const GET_AUCTION_METRICS = '/getAuctionMetrics';
export const ACCEPT_BID = '/onBidAccept';
export const DECLINE_BID = '/onBidDecline';
