import cx from 'classnames';

interface Props {
  className?: string;
  src: string;
  alt: string;
}

const ImageWithBlur = ({ className, src, alt }: Props) => {
  const fileId = src.replace(/^https:\/\/cdn\.filestackcontent\.com\//, '');
  const blurredSrc = `https://cdn.filestackcontent.com/blur=amount:19/${fileId}`;

  return (
    <div className={cx('relative rounded-md overflow-hidden', className)}>
      <div className="absolute top-0 left-0 w-full h-full z-[2]">
        <img className="w-full h-full object-contain" src={src} alt={alt} />
      </div>

      <div className="relative h-full w-full">
        <img
          className="w-full h-full object-cover"
          src={blurredSrc}
          alt={alt}
        />
      </div>
    </div>
  );
};

export default ImageWithBlur;
