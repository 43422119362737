import ReactDOM from 'react-dom/client';
import { QueryClientProvider, QueryClient } from 'react-query';
import { PostHogProvider } from 'posthog-js/react';
import { Helmet } from 'react-helmet';

import './index.css';
import './styles/globals.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';

import CmsDataProvider from 'contexts/CmsDataProvider';
import { IS_PRODUCTION } from 'constants/constants';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
declare global {
  interface Window {
    dataLayer: Array<Record<string, any>>;
  }
}

root.render(
  <PostHogProvider
    apiKey={process.env.REACT_APP_POSTHOG_KEY}
    options={{
      api_host: process.env.REACT_APP_POSTHOG_HOST,
    }}
  >
    <QueryClientProvider client={queryClient}>
      <CmsDataProvider>
        <Helmet>
          {IS_PRODUCTION && (
            <script
              src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
              data-document-language="true"
              type="text/javascript"
              data-domain-script="675adff2-5edd-4ea2-9676-ced916f3aa06"
            />
          )}
          {IS_PRODUCTION && (
            <script type="text/javascript">
              {`function OptanonWrapper() { }`}
            </script>
          )}
          {IS_PRODUCTION && (
            <script type="text/javascript">
              {`
                (function(w,d,s,l,i){
                  w[l]=w[l]||[];
                  w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
                  var f=d.getElementsByTagName(s)[0],
                      j=d.createElement(s), dl=l!='dataLayer'?'&l='+l:'';
                  j.async=true;
                  j.src='https://sst.autoscout24.ch/gtm.js?id='+i+dl;
                  f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','GTM-RSZX');
              `}
            </script>
          )}
        </Helmet>

        <App />
      </CmsDataProvider>
    </QueryClientProvider>
  </PostHogProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
