import { useEffect, useMemo, useRef } from 'react';
import parse from 'html-react-parser';
import cx from 'classnames';

import Modal from 'components/ui/Modal';
import Textarea from 'components/ui/Textarea';
import Button from 'components/ui/Button';

import { useCmsData } from 'contexts/CmsDataProvider';
import { useAdditionalInfo, useDeclineBid } from 'hooks/queries';
import {
  focusNextField,
  formatNumber,
} from 'utils/utils';
import { DeclineReason } from 'types/enum';

type Props = {
  isOpen: boolean;
  onDismiss: () => any;
  openCounterOffer: () => any;
};

const DeclineModal: React.FC<Props> = ({
  isOpen,
  onDismiss,
  openCounterOffer,
}) => {
  const otherReasonRef = useRef(null);
  const { t } = useCmsData();
  const {
    data: additionalInfo,
    submissionId,
    refetch,
    isFetching,
  } = useAdditionalInfo();
  const {
    vehicleInfo: { make, model, exactModel },
    auction: { highestBid },
  } = additionalInfo!;
  const {
    setCounterOffer,
    otherReason,
    setOtherReason,
    selectedReasons,
    setSelectedReasons,
    onDeclineBid,
    isDeclineBidLoading,
  } = useDeclineBid(submissionId || '', refetch, onDismiss);

  useEffect(() => {
    setCounterOffer(undefined);
    setSelectedReasons({});
  }, [isOpen, setCounterOffer, setSelectedReasons]);

  const onToggleReason = (reasonKey: DeclineReason) => {
    const updSelectedReasons = { ...selectedReasons };

    if (selectedReasons[reasonKey]) {
      delete updSelectedReasons[reasonKey];
      setSelectedReasons(updSelectedReasons);
      if (reasonKey === DeclineReason.Other) {
        setOtherReason('');
      }
    } else {
      updSelectedReasons[reasonKey] = true;
      setSelectedReasons(updSelectedReasons);

      setTimeout(() => {
        if (reasonKey === DeclineReason.Other) {
          focusNextField(otherReasonRef, 'input');
        }
      }, 50);
    }
  };

  const reasons = useMemo(
    () => [
      {
        name: t('dashboard_decline_modal_reason_low_bid'),
        value: DeclineReason.LowBid,
      },
      {
        name: t('dashboard_decline_modal_reason_trade_in_canceled'),
        value: DeclineReason.TradeInCanceled,
      },
      {
        name: t('dashboard_decline_modal_reason_other'),
        value: DeclineReason.Other,
      },
    ],
    [t]
  );

  return (
    <Modal
      isOpen={isOpen}
      title={t('dashboard_decline_modal_title')}
      onDismiss={onDismiss}
      content={
        <>
          <div>
            {make} {model} {exactModel}
          </div>

          <div className="mt-4 p-6 flex items-center justify-center flex-col border border-light rounded">
            <p>{t('dashboard_decline_modal_highest_bid')}</p>
            <div className="mt-1 font-bold text-[32px] leading-[40px]">
              {formatNumber(highestBid || 0, {
                price: true,
                hiddenFloat: false,
              })}
            </div>
          </div>

          <div className="mt-6">
            <div>{t('dashboard_decline_modal_reasons_title')}</div>
            <div className="mt-4 flex flex-wrap gap-4">
              {reasons.map((reason) => (
                <div
                  key={reason.value}
                  className={cx(
                    'py-1.5 px-4 w-fit text-sm rounded-full bg-light font-bold cursor-pointer select-none',
                    {
                      'bg-primary scale-[1.1] mx-1.5':
                        selectedReasons?.[reason.value],
                    }
                  )}
                  style={{
                    transitionProperty: 'margin, transform',
                    transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
                    transitionDuration: '150ms',
                  }}
                  onClick={() => onToggleReason(reason.value)}
                >
                  {reason.name}
                </div>
              ))}
            </div>
          </div>

          <div className="mt-10">
            {selectedReasons.other && (
              <Textarea
                ref={otherReasonRef}
                placeholder={t(
                  'dashboard_decline_modal_other_reason_input_placeholder'
                )}
                value={otherReason}
                onChange={(value) => setOtherReason(value || '')}
                maxLength={450}
              />
            )}

            <p className="pt-[50px] html-md">
              {parse(t('dashboard_decline_modal_hint'))}
            </p>
          </div>
        </>
      }
      footer={
        <div className="flex items-center gap-x-3">
          <Button
            className="w-1/2"
            label={t('dashboard_decline_modal_confirm_btn')}
            icon={null}
            onClick={
              selectedReasons.lowPrice || selectedReasons.other
                ? () => {
                    openCounterOffer();
                    onDismiss();
                  }
                : onDeclineBid
            }
            isLoading={isDeclineBidLoading || isFetching}
            isDisabled={!Object.keys(selectedReasons).length}
          />
          <Button
            className="w-1/2"
            label={t('dashboard_decline_modal_cancel_btn')}
            icon={null}
            onClick={onDismiss}
            outline
          />
        </div>
      }
    />
  );
};

export default DeclineModal;
