import { useMutation } from 'react-query';
import parse from 'html-react-parser';

import Modal from 'components/ui/Modal';
import Button from 'components/ui/Button';

import { acceptBid } from 'api/api';
import { useCmsData } from 'contexts/CmsDataProvider';
import { useAdditionalInfo } from 'hooks/queries';
import { formatNumber } from 'utils/utils';

type Props = {
  isOpen: boolean;
  onDismiss: () => any;
};

const AcceptModal: React.FC<Props> = ({ isOpen, onDismiss }) => {
  const { t } = useCmsData();
  const {
    data: additionalInfo,
    submissionId,
    refetch,
    isFetching,
  } = useAdditionalInfo();
  const {
    vehicleInfo: { make, model, exactModel },
    auction: { highestBid },
  } = additionalInfo!;

  const { mutate, isLoading } = useMutation(
    'acceptBid',
    () => acceptBid(submissionId!),
    {
      onSuccess: async () => {
        await refetch();
        onDismiss();
      },
      onError: () =>
        alert(
          'Unfortunately something went wrong! Please try again later or contact us.'
        ),
    }
  );

  return (
    <Modal
      isOpen={isOpen}
      title={t('dashboard_accept_modal_title')}
      onDismiss={onDismiss}
      content={
        <div>
          <div className="text-lg">
            {make} {model} {exactModel}
          </div>

          <div className="mt-4 p-6 flex items-center justify-center flex-col border border-light rounded">
            <p className="text-lg">{t('dashboard_accept_modal_highest_bid')}</p>
            <div className="mt-1 font-bold text-[32px] leading-[40px]">
              {formatNumber(highestBid || 0, {
                price: true,
                hiddenFloat: false,
              })}
            </div>
          </div>

          <p className="mt-4 html-md">
            {parse(t('dashboard_accept_modal_hint'))}
          </p>
        </div>
      }
      footer={
      <>
        <div className="flex items-center gap-x-3">
          <Button
              className="w-1/2"
              label={t('dashboard_accept_modal_confirm_btn')}
              icon={null}
              onClick={mutate}
              isLoading={isLoading || isFetching}
          />
          <Button
              className="w-1/2"
              label={t('dashboard_accept_modal_cancel_btn')}
              icon={null}
              onClick={onDismiss}
              outline
          />
        </div>
        <p className="mt-4 html-md">
          {parse(t('dashboard_accept_modal_hint_bottom'))}
        </p>
      </>
      }
    />
  );
};

export default AcceptModal;
