import { useEffect, useState } from 'react';
import Modal from 'components/ui/Modal';
import Input from 'components/ui/Input';
import Button from 'components/ui/Button';
import { useCmsData } from 'contexts/CmsDataProvider';
import { numberWithSeparator } from 'utils/utils';
import type { AdditionalInfo } from 'types/types';

type Props = {
  isOpen: boolean;
  onDismiss: () => void;
  onSubmit: (month: number, year: number) => void;
  month: number;
  year: number;
  vehicleInfo: AdditionalInfo['vehicleInfo'];
};

const EditFirstRegistrationModal: React.FC<Props> = ({
  isOpen,
  vehicleInfo,
  onDismiss,
  onSubmit,
  month,
  year,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [isValidDate, setIsValidDate] = useState(false);

  useEffect(() => {
    setInputValue('');
    setIsValidDate(false);
  }, [isOpen]);

  const { t } = useCmsData();
  const { make, model, exactModel, mileage } = vehicleInfo;

  const formatInput = (value: string) => {
    let sanitized = value.replace(/[^\d]/g, '');
    const isDeleting = sanitized.length < inputValue.length;

    if (sanitized.length === 1 && parseInt(sanitized, 10) > 1) {
      sanitized = `0${sanitized}`;
    }

    let monthPart = sanitized.slice(0, 2);
    if (parseInt(monthPart, 10) > 12) monthPart = '12';

    let formatted = monthPart;
    if (!isDeleting || sanitized.length > 2) formatted += '.';
    formatted += sanitized.slice(2, 6);

    setInputValue(formatted.slice(0, 7));
    validateDate(formatted);
  };

  const validateDate = (dateString: string) => {
    const [monthStr, yearStr] = dateString.split('.');
    const month = parseInt(monthStr, 10) - 1;
    const year = parseInt(yearStr, 10);
    const isValid =
      month >= 0 &&
      month <= 11 &&
      year >= 1930 &&
      new Date(year, month, 1) < new Date();

    setIsValidDate(isValid);
  };

  const handleSubmit = () => {
    if (!isValidDate) return;
    const [monthStr, yearStr] = inputValue.split('.');
    onSubmit(parseInt(monthStr, 10) - 1, parseInt(yearStr, 10));
  };

  return (
    <Modal
      isOpen={isOpen}
      title={t('edit_first_registration_modal_title')}
      onDismiss={onDismiss}
      content={
        <div className="pb-[10px]">
          <div>
            {make} {model} {exactModel}
          </div>
          <div>
            {numberWithSeparator(vehicleInfo.editedMileage || mileage)}{' '}
            {t('km')}&nbsp;&nbsp;
            {(month + 1).toString().padStart(2, '0')}.{year}
          </div>
          <Input
            className="mt-4 max-w-[295px]"
            label={t('edit_first_registration_modal_input_label')}
            placeholder={t('edit_first_registration_modal_input_placeholder')}
            value={inputValue}
            maxLength={7}
            type="text"
            onChange={(value) => formatInput(value as string)}
            autoComplete="off"
          />
          {!isValidDate && inputValue.length > 6 && (
            <p className="text-danger mt-2">
              {t('edit_first_registration_modal_input_error')}
            </p>
          )}
        </div>
      }
      footer={
        <div className="flex items-center gap-x-3">
          <Button
            className="w-1/2"
            label={t('edit_first_registration_modal_save_btn')}
            isDisabled={!isValidDate}
            icon={null}
            onClick={handleSubmit}
          />
          <Button
            className="w-1/2 !bg-background !border !border-dark"
            label={t('edit_first_registration_modal_cancel_btn')}
            icon={null}
            onClick={onDismiss}
          />
        </div>
      }
    />
  );
};

export default EditFirstRegistrationModal;
