import Modal from 'components/ui/Modal';
import Button from 'components/ui/Button';
import { useCmsData } from 'contexts/CmsDataProvider';
import parse from 'html-react-parser';

type Props = {
  isOpen: boolean;
  isLoading: boolean;
  onDismiss: () => void;
  onSubmit: () => void;
};

const FinalCheckModal: React.FC<Props> = ({
  isOpen,
  isLoading,
  onDismiss,
  onSubmit,
}) => {
  const { t } = useCmsData();

  return (
    <Modal
      isOpen={isOpen}
      title={t('final_check_modal_title')}
      onDismiss={onDismiss}
      content={
        <div className="pb-[10px]">
          <p className="html-md">{parse(t('final_check_modal_text_top'))}</p>
          <ul className="list-decimal px-4 mb-5">
            <li className="ml-1 break-words overflow-wrap break-word html-md">
              {parse(t('final_check_modal_point_1'))}
            </li>
            <li className="ml-1 break-words overflow-wrap break-word html-md">
              {parse(t('final_check_modal_point_2'))}
            </li>
            <li className="ml-1 break-words overflow-wrap break-word html-md">
              {parse(t('final_check_modal_point_3'))}
            </li>
            <li className="ml-1 break-words overflow-wrap break-word html-md">
              {parse(t('final_check_modal_point_4'))}
            </li>
            <li className="ml-1 break-words overflow-wrap break-word html-md">
              {parse(t('final_check_modal_point_5'))}
            </li>
          </ul>
          <p className="html-md">{parse(t('final_check_modal_text_bottom'))}</p>
        </div>
      }
      footer={
        <div className="flex items-center gap-x-3">
          <Button
            className="w-1/2"
            label={t('final_check_modal_create_btn')}
            icon={null}
            isLoading={isLoading}
            onClick={onSubmit}
          />
          <Button
            className="w-1/2 !bg-background !border !border-dark"
            label={t('final_check_modal_back_btn')}
            icon={null}
            onClick={onDismiss}
          />
        </div>
      }
    />
  );
};

export default FinalCheckModal;
